import React, { useRef, useEffect } from 'react';
import { useLocation, Switch } from 'react-router-dom';
import ReactGA from 'react-ga';

// Utls
import AppRoute from './utils/AppRoute';
import ScrollReveal from './utils/ScrollReveal';
import { REACT_APP_GA_CODE } from './utils/env';

// Layouts
import LayoutDefault from './layouts/LayoutDefault';

// Views 
import Home from './views/Home';
import { AuthContextProvider } from './context/useAuthContext';
import { ThemeContextProvider } from './context/useThemeContext';

// Initialize Google Analytics
ReactGA.initialize(REACT_APP_GA_CODE);

const trackPage = page => {
  ReactGA.set({ page });
  ReactGA.pageview(page);
};

const App = () => {

  const childRef = useRef();
  let location = useLocation();

  useEffect(() => {
    const page = location.pathname;
    document.body.classList.add('is-loaded')
    childRef.current.init();
    trackPage(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <ThemeContextProvider>
      <AuthContextProvider>
        <ScrollReveal
          ref={childRef}
          children={() => (
            <Switch>
              <AppRoute
                exact
                path="/"
                component={Home}
                layout={LayoutDefault} />
            </Switch>
          )} />
      </AuthContextProvider>
    </ThemeContextProvider>
  );
}

export default App;