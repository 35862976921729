import React from 'react';
import classNames from 'classnames';

import { SectionProps } from '../../utils/SectionProps';
import { REACT_APP_BACKEND_URL } from '../../utils/env';

import Image from '../elements/Image';
import Button from '../elements/Button';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  // const { displayName } = useAuthContext();
  // const [videoModalActive, setVideomodalactive] = useState(false);

  // const openModal = (e) => {
  //   e.preventDefault();
  //   setVideomodalactive(true);
  // }

  // const closeModal = (e) => {
  //   e.preventDefault();
  //   setVideomodalactive(false);
  // }

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  function handleDiscordClick() {
    let url = `${REACT_APP_BACKEND_URL}/authenticate/login`;

    // if (displayName) {
    //   url = REACT_APP_URL;
    // }

    window.location.href = url;
  }

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div style={{ paddingTop: '3rem', paddingBottom: '7rem' }} className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
              A platform to get started on the things that <span className="text-color-primary">matter</span>
            </h1>
            <div className="container-xs">
              <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
                Kick start your project idea or join those with one already
              </p>
              <div className="reveal-from-bottom" data-reveal-delay="600">
                {/* <Email /> */}
                <Button
                  style={{ backgroundColor: '#7289DA', color: '#FFF' }}
                  onClick={handleDiscordClick}
                >
                  <Image
                    style={{ marginRight: '.5rem' }}
                    src={require('../../assets/images/Discord-Logo-White.svg')}
                    alt="Discord Icon"
                    height={32}
                    width={32}
                  />
                  Login with Discord
                  {/* {!displayName ? 'Login with Discord' : `Continue as ${displayName}`} */}
                </Button>
              </div>
            </div>
          </div>
          {/* <div className="hero-figure reveal-from-bottom illustration-element-01" data-reveal-value="20px" data-reveal-delay="800">
            <a
              data-video="https://player.vimeo.com/video/174002812"
              href="#0"
              aria-controls="video-modal"
              onClick={openModal}
            >
              <Image
                className="has-shadow"
                src={require('./../../assets/images/video-placeholder.jpg')}
                alt="Hero"
                width={896}
                height={504} />
            </a>
          </div> */}

          {/* <Modal
            id="video-modal"
            show={videoModalActive}
            handleClose={closeModal}
            video="https://player.vimeo.com/video/174002812"
            videoTag="iframe" /> */}
        </div>
      </div>
    </section>
  );
}

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;