import React, { createContext, useContext, useState } from 'react';
import jwtDecode from 'jwt-decode';
import { get } from 'js-cookie';

import { REACT_APP_FIREBASE_CONFIG, REACT_APP_URL } from '../../utils/env';

export const AuthContext = createContext(undefined);

export const AuthContextProvider = ({ children }) => {
    const [displayName] = useState(getDisplayName());

    function getDisplayName() {

        const key = get(`_${REACT_APP_FIREBASE_CONFIG.projectId}`);

        if (!key) {
            return;
        }

        const user = jwtDecode(key);

        return user.displayName;
    }

    if (displayName) {
        window.location.href = REACT_APP_URL;
        return null;
    }

    return (
        <AuthContext.Provider value={{ displayName }}>
            {children}
        </AuthContext.Provider>
    );
}

export const useAuthContext = () => {
    const context = useContext(AuthContext);

    if (!context) {
        throw new Error('useAuthContext must be used within AuthContextProvider');
    }

    return context;
}